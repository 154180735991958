:root {
    /*DEFAULT COLOR    */
    --theme-primary-color: #039be5;
    --theme-second-color: #028ad3;
    --theme-third-color: #0394dea3;
    --theme-fourth-color: rgb(2, 131, 200);
    --theme-fith-color: #48c7ec;
    --theme-sixth-color: black;
    /*PURPLE COLOR   
    --theme-primary-color:#ab60b8;
    --theme-second-color:#9637a7;
    --theme-third-color:#ab60b8;
    --theme-fourth-color:#76018a;
    --theme-fith-color:#ab60b8;
    --theme-sixth-color:black;
 */
    /*YELLOW COLOR  
    --theme-primary-color:#ebb206;
    --theme-second-color:#daa70c;
    --theme-third-color:#ebb206;
    --theme-fourth-color:#8e6e0d;
    --theme-fith-color:#ebb206;
    --theme-sixth-color:black;
  */
    /*GREEN COLOR 
    --theme-primary-color:#b2bf2d;
    --theme-second-color:#a6b511;
    --theme-third-color:#b2bf2d;
    --theme-fourth-color:#768204;
    --theme-fith-color:#b2bf2d;
    --theme-sixth-color:black;
   
 */
    /*BLUE COLOR    
    --theme-primary-color:#394aa5;
    --theme-second-color:#5f75ec;
    --theme-third-color:#394aa5;
    --theme-fourth-color:#000d54;
    --theme-fith-color:#394aa5;
    --theme-sixth-color:white;
 */
}

body {
    background-color: #eceff1;
    overflow-x: hidden;
    overflow-y: hidden;
}

.nav-bar-style-orange {
    background: #f47a00;
    color: #9dd9f5;
}

.nav-bar-style-blue {
    background: var(--theme-primary-color);
    color: #9dd9f5;
}

.font-color-blue {
    color: #9dd9f5;
}

.navbar-brand {
    color: white !important;
}

.navbar-default .navbar-nav>.active>a, .navbar-default .navbar-nav>.active>a:focus, .navbar-default .navbar-nav>.active>a:hover {
    color: white;
    background: var(--theme-second-color) !important;
    text-transform: uppercase;
}

.navbar-default .navbar-nav>li>a {
    color: white;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
    background-color: var(--theme-second-color);
    text-transform: uppercase;
    color: white;
}

.panelNews {
    background: red;
    background: var(--theme-third-color);
    padding: 5px;
    height: 100vh;
    width: 105%;
}

.container {
    width: 99% !important;
}

.icon-news {
    background-color: white;
}

.scrollable-div {
    overflow-y: scroll;
    overflow-x: none;
    height: 100vh;
}

#img-hands {
    z-index: 1000000;
    float: right;
    background: var(--theme-primary-color);
    border-radius: 50px;
    padding: 2%;
    margin-right: 11px;
    border: 4px dashed #eceff1;
    position: absolute;
    right: -10px;
    bottom: 45px;
}

/*NEWS*/

.mb-60 {
    margin-bottom: 20px;
}

.services-inner {
    border: 2px solid var(--theme-fith-color);
    margin-left: 35px;
    transition: .3s;
    background: #fff none repeat scroll 0 0;
    border: 2px solid transparent;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.our-services-img {
    float: left;
    margin-left: -36px;
    margin-right: 22px;
    margin-top: 28px;
}

.our-services-img:hover {
    background: #ffffffc4 none repeat scroll 0 0;
    border: 2px solid transparent;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.our-services-text {
    padding-right: 10px;
}

.our-services-text {
    overflow: hidden;
    padding: 28px 0 25px;
}

.our-services-text h4 {
    color: #222222;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 8px;
    padding-bottom: 10px;
    position: relative;
    text-transform: uppercase;
}

.our-services-text h4::before {
    background: #ec6d48 none repeat scroll 0 0;
    bottom: 0;
    content: "";
    height: 1px;
    position: absolute;
    width: 35px;
}

.our-services-wrapper:hover .services-inner {
    background: #ffffffc4 none repeat scroll 0 0;
    border: 2px solid transparent;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.our-services-text p {
    margin-bottom: 0;
}

p {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #666;
    margin-bottom: 15px;
}

.title-news {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 6px;
    color: white;
    box-shadow: 0px 5px 10px 1px var(--theme-fourth-color);
}

ul#menu li {
    display: inline;
}

#menu {
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.badge {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: var(--theme-second-color);
    border-radius: 10px;
    margin-right: 10px;
}

.badge-name {
    text-transform: uppercase;
    font-size: 11px;
    color: var(--theme-sixth-color);
}

/*END NEWS*/

/*POST*/

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 95%;
    color: white;
    height: 350px;
}

.purple-card {
    background: #ab60b8;
    border: 3px solid #ab60b8;
}

.yellow-card {
    background: #ebb206;
    border: 3px solid #ebb206;
}

.green-card {
    background: #b2bf2d;
    border: 3px solid #b2bf2d;
}

.blue-card {
    background: #394aa5;
    border: 3px solid #394aa5;
}

.white-color {
    color: white !important;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container-two {
    padding: 2px 16px;
}

.img-card {
    width: 100%;
    height: 201px;
}

.pager li>a, .pager li>span {
    display: inline-block;
    padding: 5px 14px;
    background-color: #0289d1;
    border: 1px solid #57b5e5;
    border-radius: 0px;
    color: white;
    text-transform: uppercase;
}

.pager li>a:focus, .pager li>a:hover {
    text-decoration: none;
    background-color: #57b5e5;
    color: white;
}

.div-container-scroll {
    overflow: auto;
    height: 100vh;
    width: 100%;
}

.img-post-icon {
    width: 50px;
    height: 50px;
    -webkit-transform: rotate(20deg);
    transform: rotate(11deg);
    background-color: transparent;
    position: absolute;
    right: 34px;
    bottom: 15px;
    background-color: #eceff1;
    border-radius: 25px;
}

/*END POST*/

/*SCROLL BAR STYLE*/

/* width */

::-webkit-scrollbar {
    width: 5px;
}

/* Track */

::-webkit-scrollbar-track {
    background: #a9a9a957;
}

/* Handle */

::-webkit-scrollbar-thumb {
    background:var(--theme-primary-color);
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    /*background: #eceff1;*/
    /*background:var(--theme-primary-color);*/
    background: black;
}

/*END SCROLL BAR STYLE*/

/*DETAILS*/

.white-card-box {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    color: black;
    background: white;
}

.img-details {
    width: 330px;
    height: 250px;
    margin: 11px;
    -webkit-transform: rotate(20deg);
    transform: rotate(11deg);
    margin-top: 15px;
    background-color: #eceff1;
    border: 8px dashed var(--theme-primary-color);
}

a:hover {
    text-decoration: none !important;
}

.subtitle-color-dinamic {
    color: var(--theme-primary-color);
}

ul {
    list-style: none
}

.onlyList::before {
    content: "•";
    color: var(--theme-primary-color);
    display: inline-block;
    width: 1em;
    margin-left: -1em
}

.li-custom {
    border-bottom: 1px solid #eceff1;
    background: #eceff133;
    padding-bottom: 1%;
    color: #666;
}

.p-custom {
    background-color: #eceff133;
    padding: 2%;
}

/*END DETAILS*/

/*ABOUT US*/

.breadcrumb>.active {
    color: #028ad2;
    font-weight: 400;
    font-size: 25px;
}

.img-about {
    width: 100% !important;
}

.img-writer {
    width: 100% !important;
    background: white;
    padding: 3%;
    border: 5px solid #00000012;
}

.breadcrumb {
    padding: 8px 15px;
    margin-bottom: 20px;
    list-style: none;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin-right: 15px;
    margin-left: 15px;
}

.h-text {
    margin: 0px;
}

.card-about {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 95%;
    color: white;
    background: #57b5e5;
}

.text-muted {
    color: black;
}

.p-about {
    padding: 1%;
}

.black-color {
    color: black;
}

.p-text {
    font-size: 19px;
}

/*END ABOUT US*/

/*CONTACT*/

.bg-info {
    background-color: #57b5e5;
}

.form-control {
    display: block;
    width: 98%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.card-contact {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 100%;
    color: white;
}

.color-white {
    color: white !important;
}

.text-indicator-contact {
    color: #ce2027;
    float: left;
    margin-left: 12px;
    font-weight: bold;
    font-size: 11px;
}

.required-symbol-style {
    color: #ce2027;
    float: right;
    margin-right: 15px;
    font-size: 10px;
}

textarea {
    resize: none;
}

.space-button {
    margin-right: 8px;
}

/*END CONTACT*/

.img-welcome {
    width: 100%;
    padding-top: 54px;
    padding-bottom: 50px;
}

.img-small {
    width: 300px;
}

/*APP*/

.navbar-default {
    background-color: var(--theme-primary-color);
    border-color: var(--theme-primary-color);
}

.navbar {
    position: relative;
    min-height: 50px;
    margin-bottom: 0px;
    border: none;
    width: 101vw;
}

.languageSelected {
    border-bottom: 5px solid var(--theme-primary-color);
    padding-bottom: 6px;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #eceff1;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
    background-color: #000;
}

.navbar-default .navbar-toggle {
    border-color: #f3f2f2;
}

#custom-card {
    padding: 0.5%;
}

.icon-back {
    position: relative;
    right: 14px;
    bottom: -8px;
    z-index: 10000000000000000000000000000000000;
    width: 30px;
}

.titleDetails {
    font-weight: bold;
    margin-left: 12px;
    color: var(--theme-primary-color);
    background: white;
    border-radius: 2px;
    padding: 3px;
    text-shadow: 2px 2px #eceff1;
    margin-top: 0px;
    font-size: 22px;
}

#titlePost {
    margin-left: 18px !important;
}

/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
    .news-panel {
        display: none;
    }

    ::-webkit-scrollbar-track {
        background: #eceff1;
    }
    
    /* Handle */
    
    ::-webkit-scrollbar-thumb {
        background:#eceff1;
    }
    
    /* Handle on hover */
    
    ::-webkit-scrollbar-thumb:hover {
        background: #eceff1;
    }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
    .news-panel {
        display: none;
    }

    ::-webkit-scrollbar-track {
        background: #eceff1;
    }
    
    /* Handle */
    
    ::-webkit-scrollbar-thumb {
        background:#eceff1;
    }
    
    /* Handle on hover */
    
    ::-webkit-scrollbar-thumb:hover {
        background: #eceff1;
    }
}